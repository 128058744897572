export class RatingGroup extends HTMLElement {
    #css = `  
.group {
    cursor: pointer;
    display: flex;
    justify-content: center;
}
    
.star {
    filter: grayscale(0);
    transition: filter 0.5s;
}

.star__hidden{
    display: none;
    visible: hidden;
}
    
.star[data-checked="true"] ~ .star {
    filter: grayscale(1);
}
.group:hover .star {
    filter: grayscale(0);
}
    
.star:hover ~ .star {
    filter: grayscale(1);
}
       `;

    #html = `
<div class="group">    
    <span class="star star__hidden" data-checked="true">🌟</span>
    <span class="star" data-star="1">🌟</span>
    <span class="star" data-star="2">🌟</span>
    <span class="star" data-star="3">🌟</span>
    <span class="star" data-star="4">🌟</span>
    <span class="star" data-star="5">🌟</span>
</div>
    `;

    #shadowRoot: ShadowRoot;

    static get observedAttributes() {
        return ['rating'];
    }

    constructor() {
        super();

        const template = document.createElement('template');
        template.innerHTML = this.#html;
        const templateContent = template.content;

        const style = document.createElement('style');
        style.innerText = this.#css;

        this.#shadowRoot = this.attachShadow({mode: 'open'});
        this.#shadowRoot.appendChild(style);
        this.#shadowRoot.appendChild(templateContent.cloneNode(true));
    }

    connectedCallback() {
        (this.#shadowRoot.querySelector('.group') as HTMLElement).addEventListener('click', this.#click);
    }

    disconnectedCallback() {
        (this.#shadowRoot.querySelector('.group') as HTMLElement).removeEventListener('click', this.#click);
    }

    attributeChangedCallback(name: string, _: string, newValue: string = '0') {
        if (name === 'rating') {
            this.#shadowRoot.querySelectorAll('.group .star')
                .forEach((star, index) => {
                    (star as HTMLElement).dataset.checked = `${index}` === newValue ? 'true' : 'false';
                });
        }
    }

    #click(event: Event) {
        const target = (event.target as HTMLElement);
        const group = target.closest('.group');
        target.closest('.group')?.querySelectorAll('.star').forEach(star => (star as HTMLElement).dataset.checked = 'false');
        target.dataset.checked = 'true';

        group?.dispatchEvent(
            new CustomEvent('rating-group-event', {
                composed: true,
                bubbles: true,
                detail: target.dataset.star,
            }),
        );
    }

}

customElements.define('rating-group', RatingGroup);
