const app = document.querySelector<HTMLDivElement>('main') as HTMLElement;
const ratingDialog = document.querySelector<HTMLDialogElement>('dialog') as HTMLDialogElement;
const resetButton = document.querySelector<HTMLButtonElement>('.reset');
const submitButton = document.querySelector<HTMLButtonElement>('.submit');

const roadmaps = [
    'frontend',
    'javascript',
    'angular'
];

function groupListener({target}: MouseEvent) {
    const group = ((target as HTMLElement).closest('.clickable-group') as HTMLElement);

    if (!group) {
        return;
    }

    const groupId = group.dataset.groupId ?? '';
    const [, ...groupName] = groupId.split('-');
    const listener = (event: Event) => {
        const rating = (event as CustomEvent).detail;
        group.dataset.rating = rating;

        ratingDialog.close(`${groupId}=${rating}`);
        ratingDialog.removeEventListener('rating-group-event', listener);
    }

    ratingDialog.addEventListener('rating-group-event', listener);

    ratingDialog.showModal();
    ratingDialog.querySelector('rating-group')?.setAttribute('rating', group.dataset.rating ?? '0');
    (ratingDialog.querySelector('h3') as HTMLElement).innerText = groupName.join(' ').replaceAll(':', ' ⇒ ');
}

function resetForm() {
    Object.keys(localStorage).forEach((key) => {
        const group = app.querySelector(`[data-group-id="${key}"]`);
        group?.removeAttribute('data-rating');
    });

    localStorage.clear();
}

function submitForm() {
    const data = Object.entries(localStorage).reduce((acc, [key, value]) => ({...acc, [key]: parseInt(value)}), {});

    window.open(`mailto:p.ahlers@flusso.nl?subject=${document.title}&body=${JSON.stringify(data)}`);
}

export async function main(roadmap = 'frontend', mailTo?: string) {
    if (!roadmaps.includes(roadmap.toLowerCase())) {
        roadmap = 'Frontend';
    }

    console.log('[roadmap-survey] start', roadmap, mailTo);

    if (mailTo !== null) {
        submitButton?.classList.remove('hidden');
        resetButton?.classList.remove('hidden');
    }

    const title = `Roadmap ${roadmap} Survey 2022`;
    document.title = title;
    (document.querySelector('header h1') as HTMLElement).innerText = title;

    const {default: roadmapPath} = await import(`./assets/roadmap-${roadmap.toLowerCase()}.svg`);

    app.innerHTML = await fetch(roadmapPath).then(r => r.text());

    Object.entries(localStorage).forEach(([key, value]) => {
        const group = app.querySelector(`[data-group-id="${key}"]`);
        group?.setAttribute('data-rating', value);
    });

    app.addEventListener('click', groupListener);
    ratingDialog.addEventListener('close', () => {
        const [key, value] = ratingDialog?.returnValue.split('=');

        if (key) {
            localStorage.setItem(key, value);
        }
    });
    ratingDialog.addEventListener('click', (event) => {
        const {offsetX, offsetY, target} = event;

        if (offsetX < 0 || offsetX > (target as HTMLDialogElement).offsetWidth ||
            offsetY < 0 || offsetY > (target as HTMLDialogElement).offsetHeight) {
            ratingDialog.close();
        }
    });

    resetButton?.addEventListener('click', resetForm);
    submitButton?.addEventListener('click', submitForm);
}
